import {
  useElements,
  useStripe,
  // CardNumberElement,
} from "@stripe/react-stripe-js";
import { FaSpinner } from "react-icons/fa";
import { StripeCardNumberElement } from "@stripe/stripe-js";
// import { navigate } from "gatsby";
import axios from "axios";
import React, { useState, FC } from "react";
import { Box, Button, Image, Flex, Text } from "theme-ui";
import CardInput from "./CardInput";

interface Props {
  client_secret: string;
  payload: string;
}

interface Metadata {
  title: string;
  usd_amount: number | string;
  meals?: number;
  formatted_amount: string;
  goal_id?: string;
  user_id: string;
  thumbnail?: string | null;
}

export interface Payload {
  email: string;
  amount: string;
  currency: string;
  subscription: boolean;
  action: string | "transaction" | "charge" | null;
  metadata: { [key: string]: string } | Metadata;
}

const CardSetup: FC<Props> = ({ client_secret, payload }) => {
  // fix build
  if (!client_secret && !payload) {
    return null;
  }

  const _payload: Payload = JSON.parse(
    Buffer.from(payload, "base64").toString("binary")
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null as any);
  const stripe = useStripe();
  const elements = useElements();

  // console.log({ _payload });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    if (!stripe || !elements) {
      return;
    }

    try {
      // confirm card
      const result = await stripe.confirmCardSetup(client_secret, {
        payment_method: {
          card: elements.getElement("cardNumber") as StripeCardNumberElement,
          billing_details: {
            email: _payload.email,
          },
          metadata: {
            user_id: _payload.metadata.user_id,
          },
        },
      });

      if (result.error) {
        setError(result.error?.message);
        // Display result.error.message in your UI.
      } else {
        // The setup has succeeded. Display a success message and send
        // result.setupIntent.payment_method to your server to save the
        // card to a Customer
        // charge based on one-time or subscription along with payment_method and payload

        const url = `${process.env.GATSBY_API_URL}/v1.1/stripe/pay`;

        const pay = await axios.post(url, {
          payment_method: result.setupIntent?.payment_method,
          payload: _payload,
        });

        const { status, client_secret } = pay.data;

        if (status === "succeeded") {
          // charged
          // TODO: Redirect
          window.location.href = "/payment-complete?success=1";
        } else if (
          ["requires_payment_method", "requires_action"].includes(status)
        ) {
          console.log("re-authorization");
          // Re-authorize
          await stripe
            .confirmCardPayment(client_secret, {
              payment_method: result.setupIntent?.payment_method as string,
              save_payment_method: true,
              setup_future_usage: "off_session",
            })
            .then((confirm) => {
              if (confirm.error) {
                // Show error to your customer
                // console.log(confirm.error.message);
                setError(confirm.error.message);
              } else {
                if (confirm.paymentIntent?.status === "succeeded") {
                  // The payment is complete!
                  // TODO: Redirect
                  window.location.href = "/payment-complete?success=1";
                }
              }
            });
        } else {
          // console.log("Re-authorization failed");
          setError(status);
        }
      }
    } catch (error) {
      // console.log("General error");
      setError(error?.message);
    }

    setLoading(false);
  };

  return (
    <Box>
      <Text variant="text.heading" mb={3}>
        Credit/Debit Card Information
      </Text>

      {error && (
        <Box variant="messages.error" mb={3}>
          <Text>{error}</Text>
        </Box>
      )}

      <CardInput />
      <Box mb={3}>
        <Button
          variant="buttons.primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <FaSpinner className="animate-spin" size={18} />
          ) : (
            "SUBMIT"
          )}
        </Button>
      </Box>

      <Box sx={{ m: "0px auto", width: 120 }}>
        <Image src={require("../assets/images/stripe-powered.svg")}></Image>
      </Box>

      <Flex sx={{ alignItems: "center", justifyContent: "space-around" }}>
        <Image
          src={require("../assets/images/amex.svg")}
          alt=""
          variant="images.brand"
        />
        <Image
          src={require("../assets/images/credit-card.svg")}
          alt=""
          variant="images.brand"
        />
        <Image
          src={require("../assets/images/discover.svg")}
          alt=""
          variant="images.brand"
        />
        <Image
          src={require("../assets/images/mastercard.svg")}
          alt=""
          variant="images.brand"
        />
        <Image
          src={require("../assets/images/visa.svg")}
          alt=""
          variant="images.brand"
        />
      </Flex>

      {/* <Box pt={3}>
        <Text variant="text.copy">
          Your credit or debit card info is not stored on our servers but
          processed through PCI DSS complaint payment gateways
        </Text>
      </Box> */}
    </Box>
  );
};

export default CardSetup;
