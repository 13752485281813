/** @jsx jsx */
import { FC } from "react";
import { Box, jsx } from "theme-ui";
import * as qs from "query-string";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardSetup from "../../components/CardSetup";

interface Props {
  location: Location;
}

const stripeKey = process.env.GATSBY_STRIPE_PUBLISHABLE_KEY as string;

const stripePromise = loadStripe(stripeKey);

const Blog: FC<Props> = ({ location, ...props }) => {
  const parsed = qs.parse(location.search);

  return (
    <Elements stripe={stripePromise}>
      <Box variant="layout.payment">
        <CardSetup
          client_secret={parsed.client_secret as string}
          payload={parsed.payload as string}
          {...props}
        />
      </Box>
    </Elements>
  );
};

export default Blog;
